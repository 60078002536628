import React, { useEffect } from 'react';
import styled from 'styled-components';
import threeDots from './images/3-dots-move.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Img = styled.img`
  height: 50px;
  width: 50px;
`;

function App() {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = 'https://foryouth-trume.framer.website/';
    }, 1100)
  }, [])

  return <Wrapper>
    <Img src={threeDots} />
  </Wrapper>
}

export default App;
